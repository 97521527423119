<template>
  <v-dialog v-model="visibility" width="80svw">
    <div class="modalBackground">
      <p style="font-size: 20px; margin-bottom: 0px">
        Куплені квитки на рейс
        <span style="color: #144fa9"
          >{{ trip?.route?.departure?.translations?.[0]?.name }} -
          {{ trip?.route?.destination?.translations?.[0]?.name }}</span
        >
      </p>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-row no-gutters align="center" style="margin: 20px 0px">
        <v-col class="px-0 py-0" cols="4">
          <search-field label="Пошук квитка" placeholder="Введіть тут.." />
        </v-col>
        <v-row no-gutters justify="end">
          <submit-button
            style="width: 240px; height: 48px !important"
            @click="showDownloadReportModal = true"
            >Завантажити відомість
            <div
              class="downloadTicketIcon"
              style="background-color: #fff; margin-left: 10px"
            />
          </submit-button>
        </v-row>
      </v-row>
      <loader v-if="showLoader" />
      <v-data-table
        v-else
        style="margin-top: 32px"
        :headers="headers"
        :items="ticketList"
        hide-default-footer
        hide-default-header
        :items-per-page="100"
        background-color="red"
        no-data-text="Поки що ви не додали жодного маршруту"
      >
        <template v-slot:header="{ props }">
          <th
            @click="
              !head.sortable && head.sortable !== false
                ? (sortBy = head.value)((sortDesc = !sortDesc))
                : ''
            "
            v-for="head in props.headers"
            :key="head.id"
            style="padding: 15px; text-align: center; vertical-align: middle"
          >
            <v-row
              no-gutters
              align="center"
              :justify="head.align ? head.align : 'start'"
              ><output
                style="
                  color: #4b5262;
                  text-align: center;
                  font-family: 'MacPaw Fixel';
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  cursor: pointer;
                  user-select: none;
                "
                >{{ head.text }}
                <v-icon
                  v-if="head.sortable !== false"
                  size="25px"
                  :color="head.value == sortBy ? 'black' : ''"
                  >mdi-menu-swap</v-icon
                >
              </output>
            </v-row>
          </th>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <span class="tableItem">{{ item.order_ref_id }}</span>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <div style="width: 120px; text-overflow: ellipsis">
            <span class="tableItem" style="color: #144fa9 !important"
              >{{ item?.user.first_name }} {{ item?.user.last_name }}</span
            >
          </div>
        </template>
        <template v-slot:[`item.purchase_at`]="{ item }">
          <span class="tableItem">
            {{
              new Date(item?.created_at).toLocaleTimeString("uk-UA", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
            {{
              new Date(item?.created_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}</span
          >
        </template>
        <template v-slot:[`item.qt`]="{ item }">
          <span class="tableItem"> {{ item?.seats_count }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-row no-gutters justify="center">
            <div
              class="statusBox statusName"
              :style="
                item.status == 'Payed'
                  ? ' background-color:rgba(20, 158, 81, 0.10);color: #149e51;'
                  : ' background-color:rgba(223, 58, 58, 0.10); color: #DF3A3A'
              "
            >
              {{
                item.status == "Payed"
                  ? "Оплачено"
                  : item.status == "Canceled"
                  ? "Скасовано"
                  : item.status == "NotPayed"
                  ? "Не оплачено"
                  : "Помилка"
              }}
            </div>
          </v-row>
        </template>
        <template v-slot:[`item.bonus`]="{ item }">
          <span class="tableItem" style="color: #149e51">
            -{{ item?.bonus }}</span
          >
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div style="width: 145px">
            <v-row no-gutters align="center" justify="end">
              <div
                class="detailBtn"
                style="margin-right: 12px"
                @click="(showDetailOrderDrawer = true), (detailOrder = item)"
              >
                Детальніше
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="iconBackground">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="#828282"
                      class="pointer"
                      @click="
                        (symlinks = item.symlinks),
                          (showDetailSymlinkModal = true)
                      "
                      >mdi-dots-vertical</v-icon
                    >
                  </div>
                </template>
                <span>Деталі</span>
              </v-tooltip>
            </v-row>
          </div>
        </template>
      </v-data-table>
      <pagination
        :page="page"
        :quantityPage="quantityPage"
        @changePage="changePage"
      />
    </div>
    <detail-order-drawer
      style="margin-top: 5svh"
      v-if="showDetailOrderDrawer"
      :order="detailOrder"
      @close="showDetailOrderDrawer = false"
    />
    <download-report-modal
      v-if="showDownloadReportModal"
      :visible="showDownloadReportModal"
      :trip="trip"
      @close="showDownloadReportModal = false"
    />
  </v-dialog>
</template>
  
  <script>
import modalMixin from "../../../mixins/modalMixin";
import orderHistoryService from "../../../requests/Admin/orderHistoryService";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import DetailOrderDrawer from "../../UI/detailOrderDrawer.vue";
import searchField from "../../UI/Fields/searchField.vue";
import Loader from "../../UI/Loader.vue";
import Pagination from "../../UI/pagination.vue";
import DownloadReportModal from "./downloadReportModal.vue";
export default {
  components: {
    searchField,
    DetailOrderDrawer,
    Loader,
    Pagination,
    SubmitButton,
    DownloadReportModal,
  },
  mixins: [modalMixin],
  data: () => ({
    activeStatus: "all",
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Ким продано", value: "seil", align: "start", sortable: false },
      { text: "Покупець", value: "user", align: "start", sortable: false },
      {
        text: "Дата купівлі",
        value: "purchase_at",
        align: "start",
        sortable: false,
      },
      {
        text: "Куплено (к-сть)",
        value: "qt",
        align: "center",
        sortable: false,
      },
      {
        text: "Статус квитка",
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: "Списано бонусів",
        value: "bonus",
        align: "center",
        sortable: false,
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    showLoader: true,
    showDownloadReportModal: false,
    ticketList: [],
    showDetailOrderDrawer: false,
    detailOrder: {},
    page: 1,
    quantityPage: 0,
    search: "",
    sortBy: "",
    sortDesc: false,
  }),
  props: {
    trip: {
      require: true,
    },
  },
  mounted() {
    this.getTickets();
    if (this.status !== undefined && this.status !== null) {
      this.activeStatus = this.status;
    } else {
      this.status == "all";
    }
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getTickets();
    },
    async getTickets() {
      let search = [];
      search.push("status[eq]:Payed");
      if (this.trip.id) {
        search.push(`schedule_uuid[eq]: ${this.trip.id}`);
      }
      let sortDesc = this.sortDesc == true ? "DESC" : "ASC";
      await orderHistoryService
        .getOrderHistory(
          this.page,
          search.length > 0
            ? search.toString().replaceAll(",", ";").replaceAll(".", ",")
            : this.search,
          this.sortBy,
          sortDesc
        )
        .then((res) => {
          if (res.status == "Success") {
            this.ticketList = res.data;
            this.quantityPage = res.pagination?.meta?.last_page;
            this.showLoader = false;
          }
        });
    },
  },
  watch: {
    activeStatus: {
      handler() {
        this.showLoader = true;
        this.getTickets();
      },
    },
  },
};
</script>
  
  <style scoped>
.modalBackground {
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailBtn {
  height: 36px;
  padding: 4px 8px;
  border-radius: 10px;
  background: #e7edf6;
  color: #4b5262;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-weight: 400;
  display: grid;
  place-items: center;
  cursor: pointer;
  user-select: none;
}
.detailBtn:hover {
  background: #144fa9;
  color: #fafafa;
}
</style>