<template>
  <v-col style="padding: 20px 20px">
    <div
      style="
        width: 100%;
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
      "
    >
      <img src="@/assets/img/PTransBlackLogo.png" width="122px" alt="logo" />
      <span
        style="
          color: #1b1b1b;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        "
        >від:&nbsp;{{
          new Date().toLocaleDateString("uk-UA", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        }}</span
      >
    </div>

    <v-col style="padding: 16px; margin-top: 20px">
      <p
        style="
          color: #1b1b1b;
          font-family: Roboto;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 48px;
          margin-bottom: 16px;
        "
      >
        Відомість відправлення пасажирів
      </p>
      <v-row no-gutters align="center">
        <v-col cols="3">
          <p
            style="
              color: #4b5262;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 4px;
            "
          >
            Маршрут
          </p>
          <span
            style="
              color: #1b1b1b;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
            >{{ schedule?.route?.departure?.translations?.[0]?.name }} -
            {{ schedule?.route?.destination?.translations?.[0]?.name }}</span
          >
        </v-col>
        <v-col cols="3">
          <p
            style="
              color: #4b5262;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 4px;
            "
          >
            Дата відправлення
          </p>
          <span
            style="
              color: #1b1b1b;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          > 
            {{
              new Date(schedule?.departed_at).toLocaleDateString("uk-UA", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            }}
          </span>
        </v-col>
        <v-col cols="3">
          <p
            style="
              color: #4b5262;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 4px;
            "
          >
            Час відправлення
          </p>
          <span
            style="
              color: #1b1b1b;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          >
            {{
              new Date(schedule?.departed_at).toLocaleTimeString("uk-UA", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}</span
          >
        </v-col>
        <v-col cols="3">
          <p
            style="
              color: #4b5262;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 4px;
            "
          >
            Автобус
          </p>
          <span
            style="
              color: #1b1b1b;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
            >{{ schedule?.bus?.manufacturer }} {{ schedule?.bus?.model }}</span
          >
        </v-col>
      </v-row>
      <div class="sectionLine" style="margin: 36px 0px 0px 0px" />
    </v-col>
    <div
      style="
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
        padding: 16px;
      "
    >
      <v-data-table
        :headers="headers"
        :items="reports"
        hide-default-footer
        hide-default-header
        :items-per-page="100"
        background-color="red"
        no-data-text="Немає куплених квитків на даний рейс"
      >
        <template v-slot:header="{ props }">
          <th
            v-for="head in props.headers"
            :key="head.id"
            style="padding: 7px 15px; text-align: center; vertical-align: middle"
          >
            <div
              style="display: flex; align-items: center"
              :style="
                head.align
                  ? `justify-content:${head.align}`
                  : `justify-content:start`
              "
            >
              <output
                style="
                  color: #4b5262;
                  text-align: center;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                "
                :style="head.sortable !== false ? 'cursor: pointer;' : ''"
                >{{ head.text }}
              </output>
            </div>
          </th>
        </template>
        <template v-slot:[`item.id`]="{ index }">
          <div style="width: max-content; padding: 18px 0px; min-width: 50px">
            <!-- <span class="tableItem">{{ item.id }}</span> -->
            <span>{{ index + 1 }}</span>
          </div>
        </template>
        <template v-slot:[`item.route`]="{ item }">
          <div style="width: max-content">
            <span class="tableItem"
              >{{
                item?.departure?.translations.find(
                  (translate) => translate.lang == "ua"
                ).name
              }}
              -
              {{
                item?.destination?.translations.find(
                  (translate) => translate.lang == "ua"
                ).name
              }}</span
            >
          </div>
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <span class="tableItem">{{ item.order_ref_id }}</span>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <span class="tableItem" v-if="item.user !== null">
            {{ item.user?.first_name }} {{ item.user?.last_name }}
          </span>
          <span class="tableItem" v-else>
            {{ item.tickets?.[0]?.first_name }}
            {{ item.tickets?.[0]?.last_name }}
          </span>
        </template>
        <template v-slot:[`item.phone_number`]="{ item }">
          <span
            class="tableItem"
            v-if="
              item.user &&
              item.user.phone_number &&
              item.user.phone_number !== 'undefined' &&
              item.user.phone_number !== 'null' &&
              item.user !== null
            "
            >{{ item.user?.phone_number }}</span
          ><span v-else-if="item.user == null" class="tableItem">
            {{ item.tickets?.[0]?.phone_number }}</span
          >
          <span v-else class="tableItem">-</span>
        </template>
        <template v-slot:[`item.qt_ticket`]="{ item }">
          <span class="tableItem">{{ item.seats_count }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <p class="tableItem">
            {{
              item.status == "Payed"
                ? "Оплачено"
                : item.status == "PayInBus"
                ? "Оплата при посадці"
                : item.status == "NotPayed"
                ? "Не оплачено"
                : item.status == "Refunded"
                ? "Повернуто кошти"
                : item.status == "Booking" || item.status == "PreBooking"
                ? "Заброньовано"
                : "Скасовано"
            }}
          </p>
        </template>
        <template v-slot:[`item.final_price`]="{ item }">
          <span class="tableItem">{{ item.final_price }} грн</span>
        </template>
        <template v-slot:[`item.note`]="{ item }">
          <span class="tableItem">{{ item.comment || "-" }}</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div style="width: max-content">
            <v-row no-gutters align="center" justify="end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="iconBackground"
                    @click="
                      (detailOrder = item), (showDetailReportDrawer = true)
                    "
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="#828282"
                      class="pointer"
                      >mdi-dots-vertical</v-icon
                    >
                  </div>
                </template>
                <span>Деталі</span>
              </v-tooltip>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "№",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Рейс", value: "route", align: "start", sortable: false },
      {
        text: "Номер квитка",
        value: "order_id",
        align: "center",
        sortable: true,
      },
      {
        text: "ПІБ Пасажира",
        value: "user",
        align: "center",
        sortable: false,
      },
      {
        text: "Кількість місць",
        value: "qt_ticket",
        align: "center",
        sortable: true,
      },
      {
        text: "Номер телефону",
        value: "phone_number",
        align: "center",
        sortable: false,
      },
      {
        text: "Статус квитка",
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: "Вартість",
        value: "final_price",
        align: "center",
        sortable: true,
      },
      {
        text: "Примітка",
        value: "note",
        align: "start",
        sortable: true,
      },
    ],
  }),
  props: {
    reports: {
      require: true,
    },
    schedule: {
      require: true,
    },
  },
};
</script>

<style scoped>
.tableItem {
  overflow: hidden;
  color: #1b1b1b;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>