<template>
  <v-dialog v-model="visibility" width="720px">
    <div style="background-color: #fff; padding: 16px">
      <h3
        style="
          color: #1b1b1b;
          font-family: 'MacPaw Fixel';
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 8px;
        "
      >
        Завантажити відомість
      </h3>
      <p
        style="
          color: #4b5262;
          font-family: 'MacPaw Fixel';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0px;
        "
      >
        Період звіту можливий від початку створення рейсу до його відправлення
      </p>
      <div class="sectionLine" style="margin: 20px 0px" />
      <v-menu offset-y max-width="300px" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            placeholder="За увесь період"
            height="48px"
            v-model="dates"
            v-bind="attrs"
            v-on="on"
            clearable
          >
            <template v-slot:prepend-inner>
              <img
                src="@/assets/img/iconsSvg/calendarIcon.svg"
                style="margin-top: 3px"
              />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          locale="uk-UA"
          color="#144FA9"
          range
          v-model="dates"
          no-title
          :min="minDate"
          :max="new Date().toISOString().substring(0, 10)"
        />
      </v-menu>
      <submit-button
        @click="downloadReport"
        style="font-weight: 600; font-size: 18px; height: 48px !important"
        :loading="downloadReportBtnLoading"
        >Завантажити</submit-button
      >
      <v-row no-gutters justify="center" style="margin-top: 14px">
        <cancel-btn text="Скасувати" @click="$emit('close')" />
      </v-row>
    </div>
    <vue-html2pdf
      v-if="trip !== null"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      :paginate-elements-by-height="800"
      :filename="`Відправлення ${
        trip?.route?.departure?.translations?.[0]?.name
      } - ${trip?.route?.destination?.translations?.[0]?.name} ${new Date(
        trip?.departed_at
      ).toLocaleDateString('uk-UA', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'Europe/Kiev',
        hour: '2-digit',
        minute: '2-digit',
      })}`"
      pdf-format="a4"
      :pdf-margin="1"
      pdf-orientation="landscape"
      ref="html2Pdf"
      pdf-content-width="1140px"
    >
      <section slot="pdf-content">
        <pdf-report :reports="reports" :schedule="trip" />
      </section>
    </vue-html2pdf>
  </v-dialog>
</template>

<script>
import orderHistoryService from "@/requests/Admin/orderHistoryService.js";
import modalMixin from "../../../mixins/modalMixin";
import submitButton from "../../UI/Buttons/submitButton.vue";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import VueHtml2pdf from "vue-html2pdf";
import PdfReport from "./pdfReport.vue";
export default {
  components: { submitButton, CancelBtn, VueHtml2pdf, PdfReport },
  mixins: [modalMixin],
  data: () => ({
    reports: [],
    dates: null,
    downloadReportBtnLoading: false,
  }),
  props: {
    trip: {
      require: true,
    },
  },
  methods: {
    async downloadReport() {
      this.downloadReportBtnLoading = true;
      this.reports = [];
      let search = [];
      search.push(`schedule_uuid[eq]:${this.trip.id};status[eq]:Payed`);
      if (this.dates !== null && this.dates.length > 0) {
        search.push(
          `paid_at[gte]:${new Date(this.dates?.[0])?.getTime() / 1000}`
        );
      }
      if (this.dates !== null && this.dates.length > 1) {
        search.push(
          `paid_at[lte]:${new Date(this.dates?.[1])?.getTime() / 1000}`
        );
      }
      await orderHistoryService
        .getOrdersByScheduleId(`[${search.toString().replaceAll(",", ";")}]`)
        .then(async (res) => {
          if (res.status == "Success") {
            this.reports.push(...res.data);
          }
        });
      let search_ = [];
      search_.push(`schedule_uuid[eq]:${this.trip.id};status[eq]:PayInBus`);
      if (this.dates !== null && this.dates.length > 0) {
        search.push(
          `created_at[gte]:${new Date(this.dates?.[0])?.getTime() / 1000}`
        );
      }
      if (this.dates !== null && this.dates.length > 1) {
        search_.push(
          `created_at[lte]:${new Date(this.dates?.[1])?.getTime() / 1000}`
        );
      }
      await orderHistoryService
        .getOrdersByScheduleId(`[${search_.toString().replaceAll(",", ";")}]`)
        .then(async (res) => {
          if (res.status == "Success") {
            this.reports.push(...res.data);
            await this.$refs.html2Pdf.generatePdf();
            this.downloadReportBtnLoading = false;
          }
        });
    },
  },
  computed: {
    minDate() {
      if (this.dates && this.dates.length > 0) {
        return new Date(this.dates?.[0]).toISOString().substring(0, 10);
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
</style>