import requestService from "../requestService";
import store from '@/store';
export default {
    async getStartCities() {
        const response = await requestService.get(`/cp_dash/search`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async getNextCities(start_city) {
        const response = await requestService.get(`/cp_dash/search/${start_city}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async searchSchedule(start_city, end_city, start_date, end_date, qty) {
        const response = await requestService.get(`/cp_dash/search/${start_city}/${end_city}?start_date=${start_date}&end_date=${end_date}&ticket_count=${qty}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async createOrder(form) {
        const response = await requestService.post(`/cp_dash/order`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}