<template>
  <v-col class="px-0 py-0">
    <v-expand-transition>
      <v-col class="px-0 py-0" v-if="$route.name == 'tripsComponent'">
        <admin-search-ticket-form
          style="margin-top: 20px"
          v-if="user_role == 'dispatcher'"
        />
        <v-row
          no-gutters
          align="center"
          style="margin-top: 20px"
          v-if="user_role == 'admin'"
        >
          <v-col class="px-0 py-0" style="margin-right: 10px">
            <statistic-card
              :numbers="statistic.count_active"
              title="Активних квитків"
              subtitle="В продажі"
            />
          </v-col>
          <v-col
            class="px-0 py-0"
            style="margin-left: 10px; margin-right: 10px"
          >
            <statistic-card
              :numbers="statistic.count_today"
              title="Рейс виконується"
              subtitle="Сьогодні"
            />
          </v-col>
          <v-col class="px-0 py-0" style="margin-left: 10px">
            <statistic-card
              :numbers="statistic.ticket_today"
              title="Проданих квитків"
              subtitle="За сьогодні"
            />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          style="margin-top: 40px"
          v-if="user_role == 'admin'"
        >
          <v-col cols="4" class="px-0 py-0">
            <searchField
              label="Пошук відправлення"
              placeholder="Введіть тут.."
              @search="setSearchField"
            ></searchField>
          </v-col>
          <v-row no-gutters justify="end">
            <submit-button
              style="height: 48px !important; width: 240px !important"
              @click="$router.push({ name: 'createTrip' })"
            >
              <v-icon style="margin-right: 8px">mdi-plus</v-icon>Додати
              відправлення</submit-button
            >
          </v-row>
        </v-row>
        <trips-admin-list @editTrip="editTrip" :searchValue="search" />
      </v-col>
    </v-expand-transition>
    <v-expand-transition>
      <!-- <create-new-trip-component
        v-if="isCreateNewTrip"
        :isEdit="isEdit"
        @cancel="
          (isCreateNewTrip = false), (isEdit = false), (tripForEdit = {})
        "
      /> -->
      <router-view v-if="$route.name !== 'tripsComponent'" />
    </v-expand-transition>
  </v-col>
</template>

<script>
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import statisticCard from "../../UI/Cards/statisticCard.vue";
//import CreateNewTripComponent from "./createNewTripComponent.vue";
import TripsAdminList from "./tripsAdminList.vue";
import searchField from "./../../UI/Fields/searchField.vue";
import { mapGetters } from "vuex";
import adminSearchTicketForm from "@/components/Admin/Main/adminSearchTicketForm.vue";
import tripService from "../../../requests/Admin/tripService";
export default {
  components: {
    statisticCard,
    SubmitButton,
    TripsAdminList,
    adminSearchTicketForm,
    //CreateNewTripComponent,
    searchField,
  },
  data: () => ({
    isCreateNewTrip: false,
    isEdit: false,
    tripForEdit: {},
    statistic: {},
    search: "",
  }),
  mounted() {
    this.getTripStatistic();
  },
  methods: {
    async getTripStatistic() {
      await tripService.getTripsStatistic().then((res) => {
        if (res.status == "Success") {
          this.statistic = res.data;
        }
      });
    },
    editTrip() {
      this.isCreateNewTrip = true;
      this.isEdit = true;
    },
    setSearchField(search) {
      if (search !== "" && search !== undefined && search !== null) {
        this.search = `all[like]:${search}`;
      } else {
        this.search = "";
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
    user_role() {
      return this.loggedUserPTrans?.role;
    },
  },
  watch: {
    isCreateNewTrip: {
      handler() {
        this.$vuetify.goTo(0, 0);
      },
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>