<template>
  <v-navigation-drawer
    absolute
    temporary
    right
    height="90svh"
    width="400px"
    style="padding: 0px 0px; border-radius: 10px 0px 0px 10px; position: fixed"
    v-model="drawer"
  >
    <div style="padding: 20px">
      <v-row no-gutters align="center">
        <h2
          style="
            color: #1b1b1b;
            font-family: 'MacPaw Fixel';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          "
        >
          Рейс
          <span style="color: #144fa9"
            >{{ trip?.route?.departure?.translations?.[0]?.name }} -
            {{ trip?.route?.destination?.translations?.[0]?.name }}</span
          >
        </h2>
        <v-row no-gutters justify="end">
          <v-btn icon @click="$emit('close')">
            <v-icon size="26px" color="#1b1b1b">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-row>
      <div class="sectionLine" />
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Статус</span>
        </v-col>
        <v-col cols="7">
          <span
            class="fieldName"
            :style="
              trip.status == 'Active' ? 'color: #149e51' : 'color:#DF3A3A'
            "
            >{{ trip.status == "Active" ? "Активний" : "Неактивний" }}</span
          >
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Маршрут</span>
        </v-col>
        <v-col
          cols="7"
          style="cursor: pointer"
          @click="
            $router.push({ name: 'editRoute', params: { id: trip.route.id } })
          "
        >
          <v-row no-gutters align="center">
            <span class="fieldName" style="color: #144fa9"
              >{{ trip?.route?.departure?.translations?.[0]?.name }} -
              {{ trip?.route?.destination?.translations?.[0]?.name }}</span
            >
            <v-row no-gutters justify="end">
              <v-icon color="#144FA9">mdi-chevron-right</v-icon>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Відправлення</span>
        </v-col>
        <v-col cols="7">
          <span class="fieldName">
            {{
              new Date(trip?.departed_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "Europe/Kiev",
              })
            }},
            {{
              new Date(trip?.departed_at).toLocaleTimeString("uk-UA", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}</span
          >
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Прибуття</span>
        </v-col>
        <v-col cols="7">
          <span class="fieldName">
            {{
              new Date(trip?.arrival_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                timeZone: "Europe/Kiev",
              })
            }},
            {{
              new Date(trip?.arrival_at).toLocaleTimeString("uk-UA", {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}</span
          >
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">В дорозі</span>
        </v-col>
        <v-col cols="7">
          <span class="fieldName">{{ trip?.route?.total_time }}</span>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Вільних місць</span>
        </v-col>
        <v-col cols="7">
          <span class="fieldName">{{ trip?.seats_available }}</span>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Куплено квитків</span>
        </v-col>
        <v-col cols="7">
          <v-row
            no-gutters
            align="center"
            style="cursor: pointer"
            @click="showTripsTicketsModal = true"
          >
            <span class="fieldName" style="color: #149e51">{{
              trip?.seats - trip?.seats_available
            }}</span>
            <v-row no-gutters justify="end">
              <v-icon color="#144FA9">mdi-chevron-right</v-icon>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Автобус</span>
        </v-col>
        <v-col
          cols="7"
          style="cursor: pointer"
          @click="
            $router.push({ name: 'editAutopark', params: { id: trip.bus.id } })
          "
        >
          <v-row no-gutters align="center">
            <span class="fieldName" style="color: #144fa9"
              >{{ trip?.bus?.manufacturer }} {{ trip?.bus?.model }}</span
            >
            <v-row no-gutters justify="end">
              <v-icon color="#144FA9">mdi-chevron-right</v-icon>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        align="center"
        style="border-bottom: 1px solid #f4f4f4; padding: 8px 0px"
      >
        <v-col cols="5" style="padding-right: 20px">
          <span class="fieldName">Номер автобуса</span>
        </v-col>
        <v-col cols="7">
          <span class="fieldName">{{ trip?.bus?.government_number }}</span>
        </v-col>
      </v-row>
      <v-btn
        v-if="trip.status == 'Active'"
        class="downloadReportBtn"
        width="100%"
        height="48px"
        outlined
        color="#144FA980"
        @click="showDownloadReportModal = true"
        ><span style="color: #1b1b1b">Завантажити відомість</span>
        <div
          class="downloadTicketIcon"
          style="margin-left: 10px; background-color: #1b1b1b"
      /></v-btn>
      <div
        style="
          margin-top: 20px;
          border-radius: 10px;
          background: #fafafa;
          padding: 16px;
        "
      >
        <p
          style="
            color: #1b1b1b;
            font-family: 'MacPaw Fixel';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0px 0px 20px 0px;
          "
        >
          Детальний маршрут
        </p>
        <detail-route-form
          :routeList="
            trip?.route?.cities.map(
              (city) =>
                (city = {
                  ...city,
                  station: {
                    translations: city.station.translations?.[0],
                  },
                })
            )
          "
        />
      </div>
      <submit-button
        style="height: 48px !important; margin-top: 40px"
        @click="$router.push({ name: 'editTrip', params: { id: trip.id } })"
        >Редагувати</submit-button
      >
      <v-btn class="cancelBtn">Скасувати рейс</v-btn>
    </div>
    <trips-tickets-modal
      v-if="showTripsTicketsModal"
      :visible="showTripsTicketsModal"
      :trip="trip"
      @close="showTripsTicketsModal = false"
    />
    <download-report-modal
      v-if="showDownloadReportModal"
      :visible="showDownloadReportModal"
      :trip="trip"
      @close="showDownloadReportModal = false"
    />
  </v-navigation-drawer>
</template>

<script>
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import detailRouteForm from "../../UI/Forms/detailRouteForm.vue";
import TripsTicketsModal from "./tripsTicketsModal.vue";
import DownloadReportModal from "./downloadReportModal.vue";
export default {
  components: {
    detailRouteForm,
    SubmitButton,
    TripsTicketsModal,
    DownloadReportModal,
  },
  data: () => ({
    drawer: true,
    showDownloadReportModal: false,
    showTripsTicketsModal: false,
    reports: [],
  }),
  props: {
    trip: {
      require: true,
    },
  },
  watch: {
    drawer: {
      handler() {
        if (this.drawer == false) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
.fieldName {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cancelBtn {
  user-select: none;
  cursor: pointer;
  color: #df3a3a;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  background: rgba(223, 58, 58, 0.1) !important;
  border-radius: 10px !important;
  width: 100% !important;
  height: 48px !important;
  margin-top: 10px;
}
.downloadReportBtn {
  border-radius: 10px;
  margin: 20px 0px;
  font-family: "MacPaw Fixel";
  color: rgba(20, 79, 169, 0.5) !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: none !important;
}
</style>